<template>
  <VuePerfectScrollbar class="e-content__inner e-content__inner--wide">
    <div class="e-content__scroll">
      <the-chat />
    </div>
  </VuePerfectScrollbar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import Loading from 'atoms/Loading/Loading'
import TheChat from 'organisms/TheChat/TheChat'

export default {
  components: {
    // Loading,
    VuePerfectScrollbar,
    TheChat
  },

  data: () => ({
    loading: false
  })
}
</script>

<style lang="scss" scoped>
@import '_assets/LiveChat';
</style>
